<template>
    <div v-if="dataSource">
        <v-form class="wrapperForm">
            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">

                    <v-card flat v-if="rejectDummy.length > 0">
                        <EDSPanel :title="'Отказ_в_подписании'" :items="rejectDummy" :isReject="true"/>
                    </v-card>

                    <v-card flat v-if="reworkDummy.length > 0">
                        <EDSPanel :title="'Доработать_перед_подписанием'" :items="reworkDummy" :isRework="true"/>
                    </v-card>

                    <v-card flat v-if="leader.length > 0">
                        <EDSPanel :title="'Подпись_руководителя'" :items="leader"/>
                    </v-card>

                    <v-card flat v-if="chancellery.length > 0">
                        <EDSPanel :title="'Подпись_канцелярии'" :items="chancellery"/>
                    </v-card>

                    <v-card flat v-if="applicants.length > 0">
                        <EDSPanel :title="'Подпись_заявителя'" :items="applicants"/>
                    </v-card>

                    <v-card flat v-if="attachEDS.length > 0">
                        <EDSPanel :title="'Подписи_вложений'" :items="attachEDS" :isAttach="true"/>
                    </v-card>

                    <v-card flat v-if="simpleEDS.length > 0">
                        <EDSPanel :title="'Простые_подписи'" :items="simpleEDS"/>
                    </v-card>

                    <v-card flat v-if="iqala.length > 0">
                        <EDSPanel :title="'Подпись_заявителя'" :items="iqala"/>
                    </v-card>

                    <v-card flat v-if="contracts.length > 0">
                        <EDSPanel :title="'Подпись_договора'" :items="contracts"/>
                    </v-card>

                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import EDSPanel from './eds-components/EDSPanel.vue';

export default {
    name: "signs-tab",
    props: {
        id: {
            type: String,
            default: null
        }
    },
    components:{
        EDSPanel
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            leader:[],
            chancellery:[],
            applicants:[],
            attachEDS:[],
            simpleEDS:[],
            rejectDummy:[],
            reworkDummy:[],
            iqala:[],
            contracts: [],
        }
    },
    methods: {
        async update() {
            
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let objType = this.$store.getters['global/actionsource/getDataSourceType'];

            let response = await httpAPI({
                url: `api/tabs/${objType === 'Resolution' ? 'resolution' : ''}signatures/${this.$props.id}`,
                method: 'GET',
                headers: { 'isCommon': this.$store.getters['global/actionsource/isDataSourceCommon'] },
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (response) {
                this.dataSource = response.data.Payload;
                this.leader = this.dataSource.Data.filter(function (e) { return e[1] == 0; });
                this.chancellery = this.dataSource.Data.filter(function (e) { return e[1] == 1; });
                this.applicants = this.dataSource.Data.filter(function (e) { return e[1] == 3; });
                this.attachEDS = this.dataSource.Data.filter(function (e) { return e[1] == 4; });
                this.simpleEDS = this.dataSource.Data.filter(function (e) { return e[1] == 5; });
                this.rejectDummy = this.dataSource.Data.filter(function (e) { return e[1] == 6; });
                this.reworkDummy = this.dataSource.Data.filter(function (e) { return e[1] == 7; });
                this.iqala = this.dataSource.Data.filter(function (e) { return e[1] == 8; });
                this.contracts = this.dataSource.Data.filter(function (e) { return e[1] == 9; });
            }
        }
    },
    async created() {
        await this.update();
    },
    mounted() {        
        this.$eventBus.$on('update-active-tab', async () => {
            if (this.$parent.isActive)
                await this.update();
        });
    },
    destroyed() {
        this.$eventBus.$off('update-active-tab');
    }
}
</script>